<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>religion-angel-1</title>
    <ellipse
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      cx="12.001"
      cy="2.25"
      rx="4.5"
      ry="1.5"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="12.001" cy="10.5" r="3.75" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9,12.75l-3.035,8.5a1.5,1.5,0,0,0,1.413,2h9.243a1.5,1.5,0,0,0,1.413-2L15,12.75"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15,12.75,22.1,8.4a.75.75,0,0,1,1.142.756c-.342,1.685-.377,5.8-3.96,6.756"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9,12.75,1.9,8.4a.75.75,0,0,0-1.142.756c.342,1.685.377,5.8,3.96,6.756"
    />
  </svg>
</template>
